const PATHS = {
  root: '/',
  edit: 'edit',
  dashboard: 'dashboard',

  //  Authentication
  login: 'login',
  register: 'register',
  verifyEmail: 'auth/verify/:token',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password/:token',
  emailVerification: 'email-verification/otp',
  emailAuthentication: 'email-verification/email',
  accountConnectionValidation: 'flagyard/validate',

  //  Invitation
  invite: 'users/invite',
  acceptInvitation: 'invite/register/:token',

  //  Courses
  courses: 'courses',
  liveCourse: 'live',
  onDemandCourse: 'on-demand',
  myCourses: 'courses/me',
  courseDetails: 'details',
  courseOverview: 'overview',
  courseAttendance: 'attendance',
  courseRegistrants: 'registrants',
  viewCourse: 'courses/:courseId',
  upcomingCourses: 'courses/upcoming',
  editCourse: 'courses/:courseId/edit',
  viewManageCourse: 'courses/:courseId',
  createCourse: 'courses/create',
  createLiveCourse: 'courses/create/live',
  createOnDemandCourse: 'courses/create/on-demand',

  //  Course Sessions
  sessions: 'sessions',
  courseSessions: 'sessions',
  viewSession: 'courses/:courseId/session/:sessionId',
  viewRecordedSession: 'courses/:courseId/recorded-session/:sessionId',

  //  User management
  users: 'users',
  admins: 'admins',
  students: 'students',
  clubMembers: 'club-members',
  clubManagers: 'club-managers',
  contentCreators: 'content-creators',

  //  Certificates
  certificates: 'certificates',
  addProfileCertificate: 'certificates/add',
  editProfileCertificate: 'certificates/edit/:certificateId',

  //  Platform Settings
  tagsSettings: 'tags',
  topicsSettings: 'topics',
  providersSettings: 'providers',
  interestsSettings: 'interests',
  generalSettings: 'general-settings',
  certificatesSettings: 'certificates',
  addTag: 'platform-settings/tags/add',
  platformSettings: 'platform-settings',
  addTopic: 'platform-settings/topics/add',
  editTag: 'platform-settings/tags/edit/:tagId',
  addInterest: 'platform-settings/interests/add',
  addProvider: 'platform-settings/providers/add',
  editTopic: 'platform-settings/topics/edit/:topicId',
  addCertificate: 'platform-settings/certificates/add',
  editInterest: 'platform-settings/interests/edit/:interestId',
  editProvider: 'platform-settings/providers/edit/:providerId',
  editCertificate: 'platform-settings/certificates/edit/:certificateId',

  //  Profile
  userProfile: ':username',
  profile: 'profile/:username',
  profileSettings: 'profile/:username/edit',
  clubProfile: 'club-profile/:clubId',
  clubProfileMembers: 'club-profile/:clubId/members',

  //  Posts
  posts: 'posts',
  myPosts: 'my-posts',
  bookmarks: 'bookmarks',
  clubPosts: 'club-posts',
  viewPost: 'posts/:postId',
  createPost: 'create-post',
  video: 'create-post/video',
  editPost: 'posts/:postId/edit',
  article: 'create-post/article',
  knowledge: 'create-post/knowledge',
  shareKnowledge: 'create-post/knowledge',

  // Club management
  manageClubs: 'manage-clubs',
  createClub: 'create',
  editClub: ':clubId/edit',

  // Others
  events: 'events',
  nafath: 'nafath/:token',
  leaderboard: 'leaderboard',
  notifications: 'notifications',

  // Forms
  clubleadersForm: 'forms/clubleaders',
};

export default PATHS;
