import { Loadable } from 'components/render';
import { lazy } from 'react';
import PATHS from './PATHS';
import routePermissions from './routePermissions';

// Lazy import of all elements
export const NoMatch = Loadable(lazy(() => import('pages/NoMatch/NoMatch')));
export const RedirectEmailVerification = Loadable(lazy(() => import('pages/Redirect/RedirectEmailVerification')));
export const MainLayout = Loadable(lazy(() => import('layouts/MainLayout/MainLayout')));
export const LandingLayout = Loadable(lazy(() => import('layouts/LandingLayout/LandingLayout')));
export const PublicLayout = Loadable(lazy(() => import('layouts/PublicLayout/PublicLayout')));
export const CoursesPublicLayout = Loadable(lazy(() => import('layouts/PublicLayout/CoursesPublicLayout')));
export const ManagementLayout = Loadable(lazy(() => import('layouts/ManagementLayout/ManagementLayout')));
export const AuthenticationLayout = Loadable(lazy(() => import('layouts/AuthenticationLayout/AuthenticationLayout')));
export const StreamLayout = Loadable(lazy(() => import('layouts/StreamLayout/StreamLayout')));
export const Landing = Loadable(lazy(() => import('pages/Landing/Landing')));
export const Login = Loadable(lazy(() => import('pages/Login/Login')));
export const AccountConnectionValidation = Loadable(lazy(() => import('pages/AccountConnectionValidation/AccountConnectionValidation')));
export const Dashboard = Loadable(lazy(() => import('pages/Dashboard/Dashboard')));
export const Register = Loadable(lazy(() => import('pages/Register/Register')));
export const StudentProfile = Loadable(lazy(() => import('pages/StudentProfile/StudentProfile')));
export const AddProfileCertificate = Loadable(lazy(() => import('pages/StudentProfile/AddProfileCertificate')));
export const EditProfileCertificate = Loadable(lazy(() => import('pages/StudentProfile/EditProfileCertificate')));
// export const Notifications = Loadable(lazy(() => import('pages/Notifications/Notifications')));
export const ClubProfile = Loadable(lazy(() => import('pages/ClubProfile/ClubProfile')));
export const ClubPosts = Loadable(lazy(() => import('pages/ClubPosts/ClubPosts')));
export const Bookmarks = Loadable(lazy(() => import('pages/Bookmarks/Bookmarks')));
export const Users = Loadable(lazy(() => import('pages/Users/Users')));
export const Admins = Loadable(lazy(() => import('pages/Users/Admins/Admins')));
export const ClubManagers = Loadable(lazy(() => import('pages/Users/ClubManagers/ClubManagers')));
export const ContentCreators = Loadable(lazy(() => import('pages/Users/ContentCreators/ContentCreators')));
export const Students = Loadable(lazy(() => import('pages/Users/Students/Students')));
export const ClubMembers = Loadable(lazy(() => import('pages/Users/ClubMembers/ClubMembers')));
export const ForgotPassword = Loadable(lazy(() => import('pages/PasswordRecovery/ForgotPassword')));
export const ResetPassword = Loadable(lazy(() => import('pages/PasswordRecovery/ResetPassword')));
export const AcceptInvitation = Loadable(lazy(() => import('pages/AcceptInvitation/AcceptInvitation')));
export const ManagePosts = Loadable(lazy(() => import('pages/Posts/ManagePosts')));
export const Home = Loadable(lazy(() => import('pages/Home/Home')));
export const Certificates = Loadable(lazy(() => import('pages/Certificates/Certificates')));
export const Courses = Loadable(lazy(() => import('pages/Courses/Courses')));
export const ViewCourse = Loadable(lazy(() => import('pages/Courses/ViewCourse')));
export const ManageCourses = Loadable(lazy(() => import('pages/ManageCourses/ManageCourses')));
export const CourseDetails = Loadable(lazy(() => import('pages/ManageCourses/CourseDetails')));
export const CourseSessions = Loadable(lazy(() => import('pages/ManageCourses/CourseSessions')));
export const CourseAttendance = Loadable(lazy(() => import('pages/ManageCourses/CourseAttendance')));
export const CourseRegistrants = Loadable(lazy(() => import('pages/ManageCourses/CourseRegistrants')));
export const ViewCourseManagement = Loadable(lazy(() => import('pages/ManageCourses/ViewCourseManagement')));
// export const Events = Loadable(lazy(() => import('pages/Events/Events')));
// export const ManageEvents = Loadable(lazy(() => import('pages/ManageEvents/ManageEvents')));
export const PlatformSettings = Loadable(lazy(() => import('pages/PlatformSettings/PlatformSettings')));
export const GeneralSettings = Loadable(lazy(() => import('pages/PlatformSettings/GeneralSettings/GeneralSettings')));
export const TopicsSettings = Loadable(lazy(() => import('pages/PlatformSettings/Topic/TopicsSettings')));
export const TagsSettings = Loadable(lazy(() => import('pages/PlatformSettings/Tag/TagsSettings')));
export const InterestsSettings = Loadable(lazy(() => import('pages/PlatformSettings/Interest/InterestsSettings')));
export const ProvidersSettings = Loadable(lazy(() => import('pages/PlatformSettings/Provider/ProvidersSettings')));
export const CertificatesSettings = Loadable(lazy(() => import('pages/PlatformSettings/Certificate/CertificatesSettings')));
export const AddTopic = Loadable(lazy(() => import('pages/PlatformSettings/Topic/AddTopic')));
export const EditTopic = Loadable(lazy(() => import('pages/PlatformSettings/Topic/EditTopic')));
export const AddProvider = Loadable(lazy(() => import('pages/PlatformSettings/Provider/AddProvider')));
export const EditProvider = Loadable(lazy(() => import('pages/PlatformSettings/Provider/EditProvider')));
export const AddTag = Loadable(lazy(() => import('pages/PlatformSettings/Tag/AddTag')));
export const EditTag = Loadable(lazy(() => import('pages/PlatformSettings/Tag/EditTag')));
export const AddInterest = Loadable(lazy(() => import('pages/PlatformSettings/Interest/AddInterest')));
export const EditInterest = Loadable(lazy(() => import('pages/PlatformSettings/Interest/EditInterest')));
export const AddCertificate = Loadable(lazy(() => import('pages/PlatformSettings/Certificate/AddCertificate')));
export const EditCertificate = Loadable(lazy(() => import('pages/PlatformSettings/Certificate/EditCertificate')));
export const EditUserProfile = Loadable(lazy(() => import('pages/EditUserProfile/EditUserProfile')));
export const InviteUser = Loadable(lazy(() => import('pages/Users/InviteUser')));
export const CreatePost = Loadable(lazy(() => import('pages/Posts/CreatePost')));
export const PostOptions = Loadable(lazy(() => import('pages/Posts/PostOptions')));
export const EditPost = Loadable(lazy(() => import('pages/Posts/EditPost')));
export const ViewPost = Loadable(lazy(() => import('pages/Posts/ViewPost')));
export const MyPosts = Loadable(lazy(() => import('pages/Posts/MyPosts')));
export const CourseOptions = Loadable(lazy(() => import('pages/ManageCourses/CourseOptions')));
export const CreateOnDemandCourse = Loadable(lazy(() => import('pages/ManageCourses/CreateOnDemandCourse')));
export const CreateLiveCourse = Loadable(lazy(() => import('pages/ManageCourses/CreateLiveCourse')));
export const EditCourse = Loadable(lazy(() => import('pages/ManageCourses/EditCourse')));
export const Nafath = Loadable(lazy(() => import('pages/Nafath/Nafath')));
export const MyCourses = Loadable(lazy(() => import('pages/Courses/MyCourses')));
export const UpcomingCourses = Loadable(lazy(() => import('pages/Courses/UpcomingCourses')));
export const ViewSession = Loadable(lazy(() => import('pages/Courses/ViewSession')));
export const EmailAuthentication = Loadable(lazy(() => import('pages/EmailVerification/EmailAuthentication')));
export const EmailVerification = Loadable(lazy(() => import('pages/EmailVerification/EmailVerification')));
export const ViewRecordedSession = Loadable(lazy(() => import('pages/Courses/ViewRecordedSession')));
export const Leaderboard = Loadable(lazy(() => import('pages/Leaderboard/Leaderboard')));
export const ProfileSettings = Loadable(lazy(() => import('pages/ProfileSettings/ProfileSettings')));
export const ClubProfileMembers = Loadable(lazy(() => import('pages/ClubProfile/ClubProfileMembers')));
export const LiveCourses = Loadable(lazy(() => import('pages/ManageCourses/LiveCourses')));
export const OnDemandCourses = Loadable(lazy(() => import('pages/ManageCourses/OnDemandCourses')));
export const CourseOverview = Loadable(lazy(() => import('pages/ManageCourses/CourseOverview')));
// Forms
export const ClubleadersForm = Loadable(lazy(() => import('pages/Forms/ClubleadersForm')));
export const ManageClubs = Loadable(lazy(() => import('pages/Clubs/ManageClubs')));
export const CreateClub = Loadable(lazy(() => import('pages/Clubs/CreateClub')));
export const EditClub = Loadable(lazy(() => import('pages/Clubs/EditClub')));

// Declared routes
// Example
// export const example = {
//  key: 'example',
//  auth: routePermissions.example
//  path: PATHS.example ,
//  element: <Example />,
// }

// ======================== Layouts ========================
export const mainLayout = {
  key: 'mainLayout',
  path: PATHS.root,
  auth: routePermissions.mainLayout,
  element: <MainLayout />,
};

export const landingLayout = {
  key: 'landingLayout',
  path: PATHS.root,
  auth: routePermissions.landingLayout,
  element: <LandingLayout />,
};

export const managementLayout = {
  key: 'managementLayout',
  path: PATHS.root,
  auth: routePermissions.managementLayout,
  element: <ManagementLayout />,
};

export const publicLayout = {
  key: 'publicLayout',
  path: PATHS.root,
  auth: routePermissions.publicLayout,
  element: <PublicLayout />,
};

export const coursesPublicLayout = {
  key: 'coursesPublicLayout',
  path: PATHS.root,
  auth: routePermissions.publicLayout,
  element: <CoursesPublicLayout />,
};

export const streamLayout = {
  key: 'streamLayout',
  path: PATHS.root,
  auth: routePermissions.streamLayout,
  element: <StreamLayout />,
};

export const authenticationLayout = {
  key: 'authenticationLayout',
  path: '*',
  auth: routePermissions.authenticationLayout,
  element: <AuthenticationLayout />,
};

export const noMatch = {
  key: 'noMatch',
  path: '*',
  element: <NoMatch titleKey="noMatch" />,
};

export const redirectEmailVerification = {
  key: 'redirectEmailVerification',
  path: '*',
  element: <RedirectEmailVerification />,
};

export const landing = {
  key: 'landing',
  element: <Landing titleKey="landing" />,
  index: true,
};

export const accountConnectionValidation = {
  key: 'accountConnectionValidation',
  path: PATHS.accountConnectionValidation,
  auth: routePermissions.accountConnectionValidation,
  element: <AccountConnectionValidation titleKey="accountConnectionValidation" />,
};
// ======================== Authentication ========================
export const login = {
  key: 'login',
  path: PATHS.login,
  auth: routePermissions.login,
  element: <Login titleKey="login" />,
};

export const dashboard = {
  key: 'dashboard',
  path: PATHS.dashboard,
  auth: routePermissions.dashboard,
  element: <Dashboard titleKey="dashboard" />,
};

export const register = {
  key: 'register',
  path: PATHS.register,
  auth: routePermissions.register,
  element: <Register titleKey="register" />,
};

export const forgotPassword = {
  key: 'forgotPassword',
  path: PATHS.forgotPassword,
  auth: routePermissions.forgotPassword,
  element: <ForgotPassword titleKey="forgotPassword" />,
};

export const resetPassword = {
  key: 'resetPassword',
  auth: routePermissions.resetPassword,
  path: PATHS.resetPassword,
  element: <ResetPassword titleKey="resetPassword" />,
};

export const emailAuthentication = {
  key: 'emailAuthentication',
  auth: routePermissions.emailAuthentication,
  path: PATHS.emailAuthentication,
  element: <EmailAuthentication titleKey="verifyEmail" />,
};

export const emailVerification = {
  key: 'emailVerification',
  auth: routePermissions.emailVerification,
  path: PATHS.emailVerification,
  element: <EmailVerification titleKey="verifyEmail" />,
};

// ======================== Platform Settings ========================
export const addTopic = {
  key: 'addTopic',
  path: PATHS.addTopic,
  auth: routePermissions.addTopic,
  element: <AddTopic titleKey="addTopic" />,
};

export const editTopic = {
  key: 'editTopic',
  path: PATHS.editTopic,
  auth: routePermissions.editTopic,
  element: <EditTopic titleKey="editTopic" />,
};

export const addProvider = {
  key: 'addProvider',
  path: PATHS.addProvider,
  auth: routePermissions.addProvider,
  element: <AddProvider titleKey="addProvider" />,
};

export const editProvider = {
  key: 'editProvider',
  path: PATHS.editProvider,
  auth: routePermissions.editProvider,
  element: <EditProvider titleKey="editProvider" />,
};

export const addTag = {
  key: 'addTag',
  path: PATHS.addTag,
  auth: routePermissions.addTag,
  element: <AddTag titleKey="addTag" />,
};

export const editTag = {
  key: 'editTag',
  path: PATHS.editTag,
  auth: routePermissions.editTag,
  element: <EditTag titleKey="editTag" />,
};

export const addInterest = {
  key: 'addInterest',
  path: PATHS.addInterest,
  auth: routePermissions.addInterest,
  element: <AddInterest titleKey="addInterest" />,
};

export const editInterest = {
  key: 'editInterest',
  path: PATHS.editInterest,
  auth: routePermissions.editInterest,
  element: <EditInterest titleKey="editInterest" />,
};

export const addCertificate = {
  key: 'addCertificate',
  path: PATHS.addCertificate,
  auth: routePermissions.addCertificate,
  element: <AddCertificate titleKey="addCertificate" />,
};

export const editCertificate = {
  key: 'editCertificate',
  path: PATHS.editCertificate,
  auth: routePermissions.editCertificate,
  element: <EditCertificate titleKey="editCertificate" />,
};

export const generalSettings = {
  key: 'generalSettings',
  path: PATHS.generalSettings,
  auth: routePermissions.generalSettings,
  element: <GeneralSettings titleKey="generalSettings" />,
  index: true,
};

export const topicsSettings = {
  key: 'topicsSettings',
  path: PATHS.topicsSettings,
  auth: routePermissions.topicsSettings,
  element: <TopicsSettings titleKey="topics" />,
};

export const tagsSettings = {
  key: 'tagsSettings',
  path: PATHS.tagsSettings,
  auth: routePermissions.tagsSettings,
  element: <TagsSettings titleKey="tags" />,
};

export const interestsSettings = {
  key: 'interestsSettings',
  path: PATHS.interestsSettings,
  auth: routePermissions.interestsSettings,
  element: <InterestsSettings titleKey="interests" />,
};

export const providersSettings = {
  key: 'providersSettings',
  path: PATHS.providersSettings,
  auth: routePermissions.providersSettings,
  element: <ProvidersSettings titleKey="providers" />,
};

export const certificatesSettings = {
  key: 'certificatesSettings',
  path: PATHS.certificatesSettings,
  auth: routePermissions.certificatesSettings,
  element: <CertificatesSettings titleKey="certificates" />,
};

export const platformSettings = {
  key: 'platformSettings',
  path: PATHS.platformSettings,
  auth: routePermissions.platformSettings,
  element: <PlatformSettings titleKey="platformSettings" />,
  children: [
    generalSettings,
    topicsSettings,
    tagsSettings,
    interestsSettings,
    providersSettings,
    certificatesSettings,
  ],
};

// ======================== Profile ========================
export const profile = {
  key: 'profile',
  path: PATHS.profile,
  auth: routePermissions.profile,
  element: <StudentProfile titleKey="profile" />,
};
export const profileSettings = {
  key: 'profileSettings',
  path: PATHS.profileSettings,
  auth: routePermissions.profileSettings,
  element: <ProfileSettings titleKey="profileSettings" />,
};

export const addProfileCertificate = {
  key: 'addProfileCertificate',
  path: `${PATHS.profile}/${PATHS.addProfileCertificate}`,
  auth: routePermissions.addProfileCertificate,
  element: <AddProfileCertificate titleKey="addProfileCertificate" />,
};

export const editProfileCertificate = {
  key: 'editProfileCertificate',
  path: `${PATHS.profile}/${PATHS.editProfileCertificate}`,
  auth: routePermissions.editProfileCertificate,
  element: <EditProfileCertificate titleKey="editProfileCertificate" />,
};

export const clubProfile = {
  key: 'clubProfile',
  path: PATHS.clubProfile,
  auth: routePermissions.clubProfile,
  element: <ClubProfile titleKey="clubProfile" />,
};

export const clubProfileMembers = {
  key: 'clubProfileMembers',
  path: PATHS.clubProfileMembers,
  auth: routePermissions.clubProfile,
  element: <ClubProfileMembers titleKey="clubMembers" />,
};

// ======================== Notifications ========================
// export const notifications = {
//   key: 'notifications',
//   path: PATHS.notifications,
//   auth: routePermissions.notifications,
//   element: <Notifications titleKey="notifications" />,
// };

// ======================== User Management ========================
export const editUserProfile = {
  key: 'editUserProfile',
  path: PATHS.userProfile,
  auth: routePermissions.editUserProfile,
  element: <EditUserProfile titleKey="editUserProfile" />,
};

export const admins = {
  key: 'admins',
  path: PATHS.admins,
  auth: routePermissions.admins,
  element: <Admins titleKey="admins" />,
};

export const clubManagers = {
  key: 'clubManagers',
  path: PATHS.clubManagers,
  auth: routePermissions.clubManagers,
  element: <ClubManagers titleKey="clubManagers" />,
  children: [
    editUserProfile,
  ],
};

export const contentCreators = {
  key: 'contentCreators',
  path: PATHS.contentCreators,
  auth: routePermissions.contentCreators,
  element: <ContentCreators titleKey="contentCreators" />,
};

export const students = {
  key: 'students',
  path: PATHS.students,
  auth: routePermissions.students,
  element: <Students titleKey="students" />,
  children: [
    editUserProfile,
  ],
};

export const users = {
  key: 'users',
  path: PATHS.users,
  auth: routePermissions.users,
  element: <Users titleKey="users" />,
  children: [
    admins,
    clubManagers,
    contentCreators,
    students,
  ],
};

export const clubMembers = {
  key: 'clubMembers',
  path: PATHS.clubMembers,
  auth: routePermissions.clubMembers,
  element: <ClubMembers titleKey="clubMembers" />,
};

// ======================== Courses ========================
export const courses = {
  key: 'courses',
  path: PATHS.courses,
  auth: routePermissions.courses,
  element: <Courses titleKey="courses" />,
};

export const viewCourse = {
  key: 'viewCourse',
  path: PATHS.viewCourse,
  auth: routePermissions.courses,
  element: <ViewCourse titleKey="courseDetails" />,
};

export const editCourse = {
  key: 'editCourse',
  path: PATHS.editCourse,
  auth: routePermissions.manageCourses,
  element: <EditCourse titleKey="editCourse" />,
};

export const liveCourses = {
  key: 'liveCourses',
  path: PATHS.liveCourse,
  auth: routePermissions.manageCourses,
  element: <LiveCourses titleKey="courseManagement" />,
  index: true,
};

export const onDemandCourses = {
  key: 'onDemandCourses',
  path: PATHS.onDemandCourse,
  auth: routePermissions.manageCourses,
  element: <OnDemandCourses titleKey="courseManagement" />,
};

export const manageCourses = {
  key: 'manageCourses',
  path: PATHS.courses,
  auth: routePermissions.manageCourses,
  element: <ManageCourses titleKey="courseManagement" />,
  children: [
    liveCourses,
    onDemandCourses,
  ],
};

export const publicCourseManagement = {
  ...manageCourses,
  key: 'publicCourseManagement',
  auth: routePermissions.publicCourseManagement,
};

export const courseDetails = {
  key: 'courseDetails',
  path: PATHS.courseDetails,
  auth: routePermissions.manageCourses,
  element: <CourseDetails titleKey="courseManagement" />,
  index: true,
};

export const courseSessions = {
  key: 'courseSessions',
  path: PATHS.courseSessions,
  auth: routePermissions.manageCourses,
  element: <CourseSessions titleKey="courseManagement" />,
};

export const courseAttendance = {
  key: 'courseAttendance',
  path: PATHS.courseAttendance,
  auth: routePermissions.manageCourses,
  element: <CourseAttendance titleKey="courseManagement" />,
};

export const courseRegistrants = {
  key: 'courseRegistrants',
  path: PATHS.courseRegistrants,
  auth: routePermissions.manageCourses,
  element: <CourseRegistrants titleKey="courseManagement" />,
};

export const courseOverview = {
  key: 'courseOverview',
  path: PATHS.courseOverview,
  auth: routePermissions.courseOverview,
  element: <CourseOverview titleKey="courseManagement" />,
};

export const viewCourseManagement = {
  key: 'viewCourseManagement',
  path: PATHS.viewManageCourse,
  auth: routePermissions.manageCourses,
  element: <ViewCourseManagement titleKey="courseManagement" />,
  children: [
    courseDetails,
    courseSessions,
    courseAttendance,
    courseOverview,
    courseRegistrants,
  ],
};

export const publicViewCourseManagement = {
  ...viewCourseManagement,
  key: 'publicViewCourseManagement',
  auth: routePermissions.publicCourseManagement,
};

export const createCourse = {
  key: 'createCourse',
  path: PATHS.createCourse,
  auth: routePermissions.createCourse,
  element: <CourseOptions titleKey="createCourse" />,
};

export const createOnDemandCourse = {
  key: 'createOnDemandCourse',
  path: PATHS.createOnDemandCourse,
  auth: routePermissions.createCourse,
  element: <CreateOnDemandCourse titleKey="createCourse" />,
};

export const createLiveCourse = {
  key: 'createLiveCourse',
  path: PATHS.createLiveCourse,
  auth: routePermissions.createCourse,
  element: <CreateLiveCourse titleKey="createCourse" />,
};

export const myCourses = {
  key: 'myCourses',
  path: PATHS.myCourses,
  auth: routePermissions.myCourses,
  element: <MyCourses titleKey="myCourses" />,
};

export const upcomingCourses = {
  key: 'upcomingCourses',
  path: PATHS.upcomingCourses,
  auth: routePermissions.upcomingCourses,
  element: <UpcomingCourses titleKey="upcomingCourses" />,
};

export const viewSession = {
  key: 'viewSession',
  path: PATHS.viewSession,
  auth: routePermissions.viewSession,
  element: <ViewSession titleKey="viewSession" />,
};

export const viewRecordedSession = {
  key: 'viewRecordedSession',
  path: PATHS.viewRecordedSession,
  auth: routePermissions.viewRecordedSession,
  element: <ViewRecordedSession titleKey="viewSession" />,
};

// ======================== Certificates ========================
export const certificates = {
  key: 'certificates',
  path: PATHS.certificates,
  auth: routePermissions.certificates,
  element: <Certificates titleKey="certificates" />,
};

// ======================== Posts ========================
export const managePosts = {
  key: 'managePosts',
  path: PATHS.posts,
  auth: routePermissions.managePosts,
  element: <ManagePosts titleKey="posts" />,
};

export const home = {
  key: 'home',
  path: PATHS.posts,
  auth: routePermissions.home,
  element: <Home titleKey="home" />,
};

export const clubPosts = {
  key: 'clubPosts',
  path: PATHS.clubPosts,
  auth: routePermissions.clubPosts,
  element: <ClubPosts titleKey="clubPosts" />,
};

export const bookmarks = {
  key: 'bookmarks',
  path: PATHS.bookmarks,
  auth: routePermissions.bookmarks,
  element: <Bookmarks titleKey="bookmarks" />,
};

export const createPost = {
  key: 'createPost',
  path: PATHS.createPost,
  auth: routePermissions.createPost,
  element: <PostOptions titleKey="createPost" />,
};

export const createArticle = {
  key: 'createArticle',
  path: PATHS.article,
  auth: routePermissions.article,
  element: <CreatePost titleKey="createPost" />,
};

export const createVideo = {
  key: 'createVideo',
  path: PATHS.video,
  auth: routePermissions.video,
  element: <CreatePost titleKey="createPost" />,
};

export const createShareKnowledge = {
  key: 'createShareKnowledge',
  path: PATHS.shareKnowledge,
  auth: routePermissions.knowledge,
  element: <CreatePost titleKey="createPost" />,
};

export const updatePost = {
  key: 'updatePost',
  path: PATHS.editPost,
  auth: routePermissions.updatePost,
  element: <EditPost titleKey="updatePost" />,
};

export const viewPost = {
  key: 'viewPost',
  path: PATHS.viewPost,
  auth: routePermissions.viewPost,
  element: <ViewPost titleKey="viewPost" />,
};

export const myPosts = {
  key: 'myPosts',
  path: PATHS.myPosts,
  auth: routePermissions.myPosts,
  element: <MyPosts titleKey="myPosts" />,
};

// ======================== Events ========================
// export const events = {
//   key: 'events',
//   path: PATHS.events,
//   auth: routePermissions.events,
//   element: <Events titleKey="events" />,
// };

// export const manageEvents = {
//   key: 'manageEvents',
//   path: PATHS.events,
//   auth: routePermissions.manageEvents,
//   element: <ManageEvents titleKey="events" />,
// };

// ======================== Invitation ========================
export const invite = {
  key: 'invite',
  path: PATHS.invite,
  auth: routePermissions.invite,
  element: <InviteUser titleKey="invite" />,
};

export const acceptInvitation = {
  key: 'acceptInvitation',
  auth: routePermissions.acceptInvitation,
  path: PATHS.acceptInvitation,
  element: <AcceptInvitation titleKey="acceptInvitation" />,
};

// ======================== Nafath ========================
export const nafath = {
  key: 'nafath',
  auth: routePermissions.nafath,
  path: PATHS.nafath,
  element: <Nafath titleKey="Nafath" />,
};

// ======================== Leaderboard ========================
export const leaderboard = {
  key: 'leaderboard',
  path: PATHS.leaderboard,
  auth: routePermissions.leaderboard,
  element: <Leaderboard titleKey="leaderboard" />,
};

// =========================|| Forms ||========================= //
export const clubleadersForm = {
  key: 'clubleadersForm',
  path: PATHS.clubleadersForm,
  auth: routePermissions.clubleadersForm,
  element: <ClubleadersForm titleKey="Clubleaders Form" />,
};

// ======================== Club Management ========================
export const manageClubs = {
  key: 'manage-clubs',
  auth: routePermissions.manageClubs,
  path: PATHS.manageClubs,
  element: <ManageClubs />,
  children: [
    {
      key: 'create-club',
      auth: routePermissions.createClub,
      path: PATHS.createClub,
      element: <CreateClub />,
    },
    {
      key: 'edit-club',
      auth: routePermissions.editClub,
      path: PATHS.editClub,
      element: <EditClub />,
    },
  ],

};
